import Logo from "../images/logo.png";
import BannerImg from "../images/banner.jpg";
import Download from "../images/download.svg";
import Google_Img from "../images/google.png";
import AppStore_Img from "../images/appstore.png";
import Bannerimg_Img from "../images/bannerimg.png";
import Register_Img from "../images/registerimg.jpg";
import About_Img from "../images/about.png";
import Arrow_SVG from "../images/arrow.svg";
import AppDownload_Img from "../images/downloadapp.png";
import FooterBg_Img from "../images/footerbg.jpg";
import Whitelogo_Img from "../images/whitelogo.png";

import Icon1 from "../images/facebook.svg";
import Icon2 from "../images/twitter.svg";
import Icon3 from "../images/instagram.svg";
import Icon4 from "../images/pinterest.svg";
import Icon5 from "../images/linkedin.svg";
import addUser from "../images/adduser.png";
import cellar from "../images/cellar.png";
import trade from "../images/trade.png";

export const IMAGES = {
  addUser,
  cellar,
  trade,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Whitelogo_Img,
  FooterBg_Img,
  AppDownload_Img,
  Arrow_SVG,
  About_Img,
  Register_Img,
  AppStore_Img,
  Google_Img,
  Logo,
  BannerImg,
  Download,
  Bannerimg_Img,
};

const appTheme = { IMAGES };
export default appTheme;
