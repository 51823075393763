import React from "react";
import { Image } from "react-bootstrap";
import { IMAGES } from "../images/images";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section
      className="footer"
      style={{ backgroundImage: `url(${IMAGES.FooterBg_Img})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Image
              src={IMAGES.Whitelogo_Img}
              data-aos="fade-up"
              data-aos-duration="1500"
              className="flogo img-fluid"
            />
            <div className="foot_links">
              <ul>
                {/* <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">About Us</Link>
                </li> */}
                <li data-aos="fade-right" data-aos-duration="1500">
                  <Link to="/">Politique de confidentialité</Link>
                </li>
                <li data-aos="fade-left" data-aos-duration="1500">
                  <Link to="/">Conditions générales d'utilisation</Link>
                </li>
              </ul>
            </div>

            <div className="social_links">
              <Link to="/">
                <Image
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  src={IMAGES.Icon1}
                  alt=""
                />
              </Link>
              <Link to="/">
                <Image
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                  src={IMAGES.Icon2}
                  alt=""
                />
              </Link>
              <Link to="/">
                <Image
                  data-aos="zoom-in"
                  data-aos-duration="2100"
                  src={IMAGES.Icon3}
                  alt=""
                />
              </Link>
              <Link to="/">
                <Image
                  data-aos="zoom-in"
                  data-aos-duration="2200"
                  src={IMAGES.Icon4}
                  alt=""
                />
              </Link>
              <Link to="/">
                <Image
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                  src={IMAGES.Icon5}
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div
            className="copyright"
            data-aos="zoom-in"
            data-aos-duration="2500"
          >
            <p className="mb-0">
              © Copyright 2024 OmniCave. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
