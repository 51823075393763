import React, { useEffect } from "react";
import Header from "./component/inc/Header";
import Home from "./component/pages/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Footer from "./component/inc/Footer";
import ScrollToTop from "./component/inc/ScrollToTop";

function ScrollToTopMain() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router basename={"/"}>
      <ScrollToTopMain />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </Router>
  );
}

export default App;
