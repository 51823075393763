import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { IMAGES } from "../images/images";

const Header = () => {
  const navbarToggleRef = useRef(null);
  const navbarCollapseRef = useRef(null);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });

    // Toggle the collapse state of the navbar
    if (
      navbarToggleRef.current &&
      navbarCollapseRef.current.classList.contains("show")
    ) {
      navbarToggleRef.current.click();
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" id="home">
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <Image src={IMAGES.Logo} width="100" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          ref={navbarToggleRef}
        />
        <Navbar.Collapse id="navbarScroll" ref={navbarCollapseRef}>
          <Nav className="ms-auto align-items-center">
            <Nav.Link
              data-aos="flip-left"
              data-aos-duration="2000"
              as={Link}
              to="/"
              onClick={() => scrollToSection("home")}
            >
              Accueil
            </Nav.Link>
            <Nav.Link
              data-aos="flip-left"
              data-aos-duration="2000"
              onClick={() => scrollToSection("aboutus")}
              as={Link}
            >
              À propos de nous
            </Nav.Link>
            <Nav.Link
              data-aos="flip-left"
              data-aos-duration="2000"
              onClick={() => scrollToSection("howitwork")}
            >
              Comment ça marche
            </Nav.Link>
            <Nav.Link
              data-aos="flip-left"
              data-aos-duration="2000"
              onClick={() => scrollToSection("registeryourself")}
            >
              Enregistrez-vous
            </Nav.Link>
            {/* <Nav.Link onClick={() => scrollToSection("registeryourself")}>
              Download Our Apps
            </Nav.Link> */}
            {/* <Nav.Link onClick={() => scrollToSection("downloadapp")}> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
